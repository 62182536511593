import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import closeBtn from "../../../../assets/icons/close.svg";
import addInstituteBtn from "../../../../assets/images/addInstitute1.svg";
import { BASE_URL } from "../../../../config/config";
import produce from "immer";
import { Toaster, toast } from "react-hot-toast";

const Modal = ({ setShow, setIsAdd, isAdd }) => {
  const token = JSON.parse(sessionStorage.getItem("token"));
  const Swal = require("sweetalert2");

  //Institution states///////
  ////////////////////////////////
  const [institutionName, setInstitutionName] = useState("");
  const [institutionAcct, setInstitutionAcct] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [institutionEmail, setInstitutionEmail] = useState("");
  const [institutionCode, setInstitutionCode] = useState("");
  const [institutionId, setInstitutionId] = useState();

  //Institution states ends here////
  //////////////////////////////////

  //Role states starts here////
  //////////////////////////////////
  const [roles, setRoles] = useState();
  const [formattedRoles, setFormattedRoles] = useState();
  const [isRoleFetching, setIsRoleFetching] = useState(false);
  //Role states ends here////
  //////////////////////////////////

  const [addSignatory, setAddSignatory] = useState(false);
  const [showSCHint, setShowSCHint] = useState(false);
  const [showLevelHint, setShowLeveHint] = useState(false);

  const createInstitution = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/institution/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: institutionName,
            code: institutionCode,
            address: institutionEmail,
            account_no: institutionAcct,
            customer_id: customerId,
          }),
        }
      );
      const data = await response.json();
      //console.log(data);
      if (
        response.status === 200 &&
        data?.detail === "Institution created successfully"
      ) {
        toast.success("Institution created successfully");
        setInstitutionName(data?.data?.name);
        setInstitutionAcct(data?.data?.account_no);
        setCustomerId(data?.data?.customerID);
        setInstitutionEmail(data?.data?.address);
        setInstitutionCode(data?.data?.code);
        setInstitutionId(data?.data?.id);
        setAddSignatory(true);
        setIsAdd(!isAdd);
      } else {
        // toast.error(data?.detail);
        Swal.fire({
          title: " ",
          text: `${data?.detail || "An error occurred, try again."}`,
          icon: "error",
          confirmButtonText: "Close",
          width: "25em",
          height: "4em",
          background: "#ffffff",
          confirmButtonColor: "#d22f31",
          timerProgressBar: true,
        });
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const createInstitutionHandler = () => {
    createInstitution();
  };

  const getRoles = async () => {
    try {
      setIsRoleFetching(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/roles/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      //console.log(data);
      setRoles(data);
      setFormattedRoles(
        [...data, { id: "", mandate_type: "Assign Role" }].reverse()
      );
      setIsRoleFetching(false);
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);
  useEffect(() => {
    //console.log(formattedRoles);
  }, [isRoleFetching]);
  const [signstories, setSignatories] = useState([
    {
      first_name: "",
      last_name: "",
      email: "",
      bvn: "",
      phone_number: "",
      level: "",
    },
  ]);

  const apiSignatory = signstories[signstories.length - 1];

  const addSignatoriesHandler = () => {
    createSignatory({ ...apiSignatory, institution_id: institutionId });
  };
  const removeSignatoryHandler = (idx) => {
    const list = [...signstories];
    list.splice(idx, 1);
    setSignatories(list);
  };

  const createSignatory = async (payload) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/mandate/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      //console.log(data);
      if (
        response.status === 200 &&
        data?.detail === "Mandate added successfully"
      ) {
        toast.success("Signatory created successfully");
        setSignatories([
          ...signstories,
          {
            first_name: "",
            last_name: "",
            email: "",
            bvn: "",
            phone_number: "",
            level: "",
          },
        ]);
      } else {
        // toast.error(data?.detail);
        Swal.fire({
          title: " ",
          text: `${data?.detail || "An error occurred, try again."}`,
          icon: "error",
          confirmButtonText: "Close",
          width: "25em",
          height: "4em",
          background: "#ffffff",
          confirmButtonColor: "#0e8142",
          timerProgressBar: true,
        });
      }
    } catch (error) {
      //console.log(error);
    }
  };

  return createPortal(
    <ModalWrapper>
      <ModalContent>
        <div className="head">
          <p>Add New Institution</p>
          <img
            src={closeBtn}
            alt=""
            onClick={() => {
              setShow(false);
            }}
          />
        </div>
        <div className="intro">
          <p>Kindly fill in the text fields to create an institution</p>
        </div>
        <div className="InputField">
          <div className="inputs">
            <label htmlFor="name">Institution Name</label>
            <input
              type="text"
              placeholder="Input Institution Name"
              value={institutionName}
              className="mb"
              onChange={(e) => {
                setInstitutionName(e.target.value);
              }}
              disabled={addSignatory ? true : false}
            />
          </div>
          <div className="inputs">
            <label htmlFor="name">Account Number</label>
            <input
              type="number"
              placeholder="Input Account Number"
              className="mb"
              value={institutionAcct}
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  setInstitutionAcct(e.target.value);
                } else {
                  return;
                }
              }}
              disabled={addSignatory ? true : false}
            />
          </div>
          <div className="inputs">
            <label htmlFor="name">Customer ID</label>
            <input
              type="number"
              placeholder="Input Customer ID"
              value={customerId}
              className="mb"
              onChange={(e) => {
                setCustomerId(e.target.value);
              }}
              disabled={addSignatory ? true : false}
            />
          </div>
          <div className="inputs">
            <label htmlFor="name">Address</label>
            <input
              type="text"
              placeholder="Input address"
              value={institutionEmail}
              onChange={(e) => {
                setInstitutionEmail(e.target.value);
              }}
              disabled={addSignatory ? true : false}
            />
          </div>
          <div className="inputs">
            <label htmlFor="name">Short Code</label>
            <input
              type="text"
              placeholder="Enter short code"
              value={institutionCode}
              onChange={(e) => {
                setInstitutionCode(e.target.value);
              }}
              disabled={addSignatory ? true : false}
              onFocus={() => {
                setShowSCHint(true);
              }}
              onBlur={() => {
                setShowSCHint(false);
              }}
            />
            {showSCHint && (
              <p className="hints">
                a unique code to identify your bank, use 3 alphabets from your
                bank name.
              </p>
            )}
          </div>
        </div>

        {addSignatory || (
          <div className="createInstitution">
            <button onClick={createInstitutionHandler}>
              Create Institution
            </button>
          </div>
        )}

        {addSignatory && (
          <SignatoriesWrapper>
            <h3>
              <u>Signatories Details </u>
            </h3>
            {signstories.map((x, idx) => {
              return (
                <div className="signatories">
                  <div className="length">
                    <p>{idx + 1}</p>
                  </div>
                  <div className="signatoriesInputField">
                    <div className="inputs">
                      <label htmlFor="name">First Name</label>
                      <input
                        type="text"
                        className="mb"
                        value={x?.first_name}
                        disabled={idx === signstories.length - 1 ? false : true}
                        onChange={(e) => {
                          const first_name = e.target.value;
                          setSignatories((currentSignatory) =>
                            produce(currentSignatory, (v) => {
                              v[idx].first_name = first_name;
                            })
                          );
                        }}
                        onKeyDown={(event) => {
                          const key = event.key;

                          if (!isNaN(key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className="inputs">
                      <label htmlFor="name">Last Name</label>
                      <input
                        type="text"
                        value={x?.last_name}
                        className="mb"
                        disabled={idx === signstories.length - 1 ? false : true}
                        onChange={(e) => {
                          const last_name = e.target.value;
                          const key = e.key;
                          setSignatories((currentSignatory) =>
                            produce(currentSignatory, (v) => {
                              if (!isNaN(key)) {
                                e.preventDefault();
                              }
                              v[idx].last_name = last_name;
                            })
                          );
                        }}
                        onKeyDown={(event) => {
                          const key = event.key;

                          if (!isNaN(key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className="inputs">
                      <label htmlFor="name">Email</label>
                      <input
                        type="email"
                        value={x?.email}
                        className="mb"
                        disabled={idx === signstories.length - 1 ? false : true}
                        onChange={(e) => {
                          const email = e.target.value;
                          setSignatories((currentSignatory) =>
                            produce(currentSignatory, (v) => {
                              v[idx].email = email;
                            })
                          );
                        }}
                      />
                    </div>
                    <div className="inputs">
                      <label htmlFor="name">BVN</label>
                      <input
                        type="number"
                        value={x?.bvn}
                        disabled={idx === signstories.length - 1 ? false : true}
                        onChange={(e) => {
                          const bvn = e.target.value;
                          setSignatories((currentSignatory) =>
                            produce(currentSignatory, (v) => {
                              if (bvn.length <= 11) {
                                v[idx].bvn = bvn;
                              } else {
                                return;
                              }
                            })
                          );
                        }}
                      />
                    </div>
                    <div className="inputs">
                      <label htmlFor="name">Phone Number</label>
                      <input
                        type="number"
                        value={x?.phone_number}
                        disabled={idx === signstories.length - 1 ? false : true}
                        onChange={(e) => {
                          const phone_number = e.target.value;
                          setSignatories((currentSignatory) =>
                            produce(currentSignatory, (v) => {
                              if (phone_number?.length <= 11) {
                                v[idx].phone_number = phone_number;
                              } else {
                                return;
                              }
                            })
                          );
                        }}
                      />
                    </div>
                    <div className="inputs">
                      <label htmlFor="name">Level</label>
                      <input
                        type="number"
                        value={x?.level}
                        disabled={idx === signstories.length - 1 ? false : true}
                        onChange={(e) => {
                          const level = e.target.value;
                          setSignatories((currentSignatory) =>
                            produce(currentSignatory, (v) => {
                              if (level === "0") {
                                return;
                              }
                              v[idx].level = level;
                            })
                          );
                        }}
                        onFocus={() => {
                          setShowLeveHint(true);
                        }}
                        onBlur={() => {
                          setShowLeveHint(false);
                        }}
                      />
                      {showLevelHint && (
                        <p className="hints">
                          1,2,3 etc. Higher number represents <br /> higher
                          level.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="addInstituteBtn">
              <img
                src={addInstituteBtn}
                alt="add-institution"
                onClick={addSignatoriesHandler}
              />
            </div>
          </SignatoriesWrapper>
        )}
      </ModalContent>
    </ModalWrapper>,
    document.getElementById("addInstitution")
  );
};

export default Modal;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  width: 85%;
  min-height: 60%;
  max-height: 80%;
  padding: 30px;
  border-radius: 15px;
  overflow-y: scroll;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    img {
      width: 45px;
      cursor: pointer;
    }
    p {
      font-size: 24px;
      letter-spacing: 1.2px;
      font-weight: 600;
    }
  }

  .intro {
    padding: 0 20px;
    margin-top: 20px;
    font-size: 15px;
    color: #333333;
  }

  .InputField {
    margin-top: 30px;
    padding-left: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .inputs {
      display: flex;
      flex-direction: column;

      label {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      input,
      select {
        width: 80%;
        height: 53px;
        border: 1px solid #d22f31;
        border-radius: 5px;
        padding: 0 10px;

        &:active,
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: #797979;
        }
      }
      .mb {
        margin-bottom: 30px;
      }
      select {
        width: 322px;
        padding: 0 20px;
      }
      /* For Safari, Chrome, and Opera browsers */
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* For Firefox browser */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .hints {
        color: red;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
  .addInstituteBtn {
    margin-top: 30px;
    padding: 0 20px;

    img {
      width: 160px;
      cursor: pointer;
    }
  }

  .createInstitution {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      background-color: #d22f31;
      width: 500px;
      height: 50px;
      border: none;
      font-size: 16px;
      outline: none;
      color: #fff;
      cursor: pointer;
    }
  }
`;

const SignatoriesWrapper = styled.div`
  margin-top: 40px;

  h3 {
    padding: 0 20px;
  }
  .signatories {
    .length {
      margin-left: 20px;
      margin-top: 30px;
      border: 1px solid;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #d22f31;
      color: #fff;
    }
    .signatoriesInputField {
      margin-top: 30px;
      padding-left: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      .inputs {
        display: flex;
        flex-direction: column;

        label {
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 5px;
        }
        input,
        select {
          width: 80%;
          height: 53px;
          border: 1px solid #d22f31;
          border-radius: 5px;
          padding: 0 0 0 10px;

          &:active,
          &:focus {
            outline: none;
          }
          &::placeholder {
            color: #797979;
          }
        }
        select {
          width: 82.5%;
          padding: 0 20px;
        }
        /* For Safari, Chrome, and Opera browsers */
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* For Firefox browser */
        input[type="number"] {
          -moz-appearance: textfield;
        }
        .mb {
          margin-bottom: 30px;
        }
        .hints {
          color: red;
          font-size: 12px;
          margin-top: 5px;
        }
      }
    }
    .removeSignatory {
      /* width: 100%; */
      display: flex;
      justify-content: flex-end;
      padding-right: 90px;

      button {
        background-color: transparent;
        border: 1px solid red;
        color: red;
        cursor: pointer;
        height: 30px;
      }
    }
  }
`;
