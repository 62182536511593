import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import styled from "styled-components";

export default function Pie({ data }) {
  //console.log(data);

  const series = [20, 40, 30, 47] && [+data[0], +data[1], +data[2], +data[3]];

  const options = {
    chart: {
      height: "400px",
      type: "donut",
      redrawOnParentResize: false,
    },
    colors: ["#ff4560", "#00e396", "#2e98df", "#fbb538", "#A1E3CB"],
    plotOptions: {
      pie: {
        donut: {
          size: "55%",
        },
        startAngle: -90,
        endAngle: 270,
        height: "300px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      show: "false",
      fonSize: "8px",
      position: "bottom",
      offsetY: 10,
      height: 60,
      fontFamily: "Helvetica, Arial",
      fontWeight: 500,
      horizontalAlign: "left",

      markers: {
        width: 8,
        height: 8,
      },
    },
    labels: ["Airtime", "Data", "Cable TV", "Electricity", "Others"],
    responsive: [
      {
        breakpoint: 414,
        options: {
          plotOptions: {
            pie: {
              horizontal: false,
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <PieWrapper>
      {" "}
      <ReactApexChart
        options={options}
        type="donut"
        series={series}
        height={400}
      />
    </PieWrapper>
  );
}

const PieWrapper = styled.div`
  width: 13.9rem;
  margin-top: 25%;
`;
