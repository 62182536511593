import React from "react";
import styled from "styled-components";

const BillsCard = ({ children, color, backgroundColor }) => {
  // const Wrapper = styled.div`
  //   /* position: relative; */
  //   background-color: ${color};
  //   width: 50px;
  //   height: 150px;
  //   border-radius: 13px;
  //   box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  //   -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  //   -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

  //   .icon {
  //     background-color: ${backgroundColor};
  //     width: 50px;
  //     height: 50px;
  //     display: flex;
  //     align-items: center;
  //     border-radius: 50%;
  //     justify-content: center;

  //     img {
  //       width: 20px;
  //       height: 20px;
  //     }
  //   }
  //   .cardName {
  //     font-size: 14px;
  //     margin: 6px 0 !important;
  //     font-weight: 600;
  //   }
  //   .amount {
  //     font-family: "DM Sans", sans-serif !important;
  //     font-size: 20px;
  //     font-weight: 600;
  //   }
  //   .counts {
  //     margin-bottom: 6px !important;
  //     font-size: 10px;
  //     font-weight: 600;
  //   }
  //   .activeInactive {
  //     display: flex;
  //     align-items: center;
  //     font-size: 11px;
  //     margin-bottom: 6px !important;

  //     .active {
  //       margin-right: 10px;
  //       display: flex;
  //       align-items: center;
  //       gap: 4px;
  //       .blip {
  //         background: #0e814a;
  //         height: 5px;
  //         width: 5px;
  //         border-radius: 50%;
  //       }
  //     }
  //     .Inactive {
  //       display: flex;
  //       align-items: center;
  //       gap: 4px;

  //       .blip {
  //         background: #ff0000b2;
  //         height: 5px;
  //         width: 5px;
  //         border-radius: 50%;
  //       }
  //     }
  //   }
  // `;

  const Card = styled.div`
  background-color: #fff;
  /* width: 100%; */
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  height: 121px;
  left: 3%;
  border-radius: 9px;
  overflow: hidden;
  padding: 0.6rem 0.9rem;
  box-shadow: 4px 0px 13px 5px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 4px 0px 13px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 4px 0px 13px 5px rgba(0, 0, 0, 0.05);
  border-left: 10px solid  ${color};

      .icon {
      background-color: ${backgroundColor};
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      border-radius: 50%;
      justify-content: center;

      img {
        width: 25px;
        height: 25px;
      }
    }
    .cardName {
      font-size: 12px;
      margin: 19px 0 15px !important;
      font-weight: 600;
      color: #909090;
    }
    .amount {
      font-family: "DM Sans", sans-serif !important;
      font-size: 20px;
      font-weight: 600;

    }
    .counts {
      margin: 12px 0 !important;
      font-size: 10px;
      font-weight: 600;
      color: #909090;

    }
    .activeInactive {
      display: flex;
      align-items: center;
      font-size: 11px;
      margin: 6px 0 !important;

      .active {
        margin-right: 10px;
        display: flex;
        align-items: center;
        gap: 4px;
        .blip {
          background: #0e814a;
          height: 5px;
          width: 5px;
          border-radius: 50%;
        }
      }
      .Inactive {
        display: flex;
        align-items: center;
        gap: 4px;

        .blip {
          background: #ff0000b2;
          height: 5px;
          width: 5px;
          border-radius: 50%;
        }
      }
`;
  return (
    <div style={{ marginTop: "30px" }}>
      <Card>{children}</Card>
    </div>
  );
};

export default BillsCard;
