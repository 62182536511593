import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Wrapper, PageContainer } from "../Home";
import {
  Content,
  DetaiLContent,
  ClientContent,
  Header,
  ContentInfo,
} from "../../Requests/approved/review/Home";
import {
  ClientInfoWrapper,
  ClientImageContainer,
  ClientName,
} from "../../Requests/pending/review/Home";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import camera from "../../../../assets/icons/camera.svg";
import downloadCloud from "../../../../assets/icons/download-cloud.svg";
import addIcon from "../../../../assets/icons/add-icon.svg";
import { BASE_URL } from "../../../../config/config";
import { AiOutlineStop, AiFillLock, AiFillUnlock } from "react-icons/ai";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import {ReactComponent as Edit} from '../../../../assets/images/edit.svg';

const Home = () => {
  const Swal = require("sweetalert2");
  const html2pdf = require("html2pdf.js/dist/html2pdf.bundle");

  const token = JSON.parse(sessionStorage.getItem("token"));
  const customerId = JSON.parse(sessionStorage.getItem("customerId"));
  const [data, setData] = useState(null);
  const [datatier, setDatatier] = useState(null);
  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [update, setUpdate] = useState(true)
  const [tier, setTier] = useState('')
  const [updateLimit, setUpdateLimit] = useState(true)
  const [editData, setEditData] = useState({
    transfer_limit: "",
    daily_limit: "",
    phone_number: "",
    staff_status: false,
    is_active: false,
    tier_id: null
  });

  const getCusomerInfo = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/1/customer/${customerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let data = await response.json();
      setData(data);
      setLoading(false);
      setEditData((prev) => {
        return {
          ...prev,
          transfer_limit: data?.transfer_limit,
          daily_limit: data?.daily_limit,
          phone_number: data?.phone_number,
          is_active: data?.active,
          staff_status: data?.customer_detail?.staff,
        };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getAllTier = async () => {
    try {
      // setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/account-tier`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();
      console.log(data)
      setDatatier(data);
      console.log('sodiq')
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCusomerInfo();
    getAllTier();
  }, []);
  console.log(editData);

  const addAdminHandler = () => {
    setEditData((prev) => {
      return { ...prev, staff_status: true };
    });
  };

  const removeFromAdminHnalder = () => {
    setEditData((prev) => {
      return { ...prev, staff_status: false };
    });
  };

  const lockAccountHandler = () => {
    setEditData((prev) => {
      return { ...prev, is_active: false };
    });
    setUpdate(false)
    setUpdateLimit(true)
  };

  const unlockAccountHandler = () => {
    setEditData((prev) => {
      return { ...prev, is_active: true };
    });
    setUpdate(false)
    setUpdateLimit(true)
  };

  const trsfLimitHandler = (e) => {
    setEditData((prev) => {
      return { ...prev, transfer_limit: e.target.value };
    });
  };
  const dailyLimitHandler = (e) => {
    setEditData((prev) => {
      return { ...prev, daily_limit: e.target.value };
    });
  };

  const updateAccountDetails = async () => {
    try {
      setPostLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/1/customer/${customerId}`,
        {
          method: "PUT",
          body: JSON.stringify(editData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      setPostLoading(false);
      if (response.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Account status/limit changed successfully",
          icon: "success",
          confirmButtonText: "Close",
          confirmButtonColor: "#d22f31",
          width: "25em",
          height: "4em",
          background: "##fff",
          color: "#000",
          timer: 3000,
          timerProgressBar: true,
        });

        getCusomerInfo();
        // toast("hi");
      } else {
        Swal.fire({
          title: " ",
          text: "An error occurred, please try again!",
          icon: "error",
          confirmButtonText: "Close",
          width: "25em",
          height: "4em",
          background: "#3d3d3d",
          color: "#ffffff",
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const fireChangesHandler = () => {
    updateAccountDetails();
  };

  const TierChange = (e) => {
    console.log(e.target.value)
    const selectedTierId = parseInt(e.target.value, 10); 
    setEditData((prev) => {
      return {...prev,
        tier_id: selectedTierId
      }
    })
    console.log(e.target.value)
    setTier(e.target.value)
    setUpdate(true)
    setUpdateLimit((prev) => !prev)
  }

  const settingUpdate = () => {
    setUpdate((prev) => !prev)
    setUpdateLimit(true)

  }

  const formattedDOB = data?.customer_detail?.dob.split(" ")[0];

  const pdfDownloadHandler = () => {
    const invoice = window?.document?.getElementById("document_to_print");
    const opt = {
      margin: 1,
      filename: "Customer Info.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
    };
    html2pdf(invoice, opt);
  };

  const myElementRef = useRef(null);

  return (
    <div id="document_to_print" ref={myElementRef}>
      <Wrapper>
        <>
          {loading === true ? (
            <Skeleton
              width={"100%"}
              height={"700px"}
              style={{ marginTop: "20px" }}
            />
          ) : (
            <PageContainer style={{ padding: "10px" }}>
              <Content>
                <Link to="/customers">
                  <BsFillArrowLeftCircleFill style={{ color: "#d22f31" }} />
                </Link>
                <DetaiLContent>
                  <ClientInfoWrapper>
                    <ClientImageContainer>
                      <img
                        style={{ width: "130px", height: "130px" }}
                        src={data?.image}
                        alt=""
                      />
                      <span className="camera-icons">
                        <img src={camera} alt="" />
                      </span>
                    </ClientImageContainer>
                    <ClientName>
                      <h3>
                        {data?.customer_detail?.first_name}{" "}
                        {data?.customer_detail?.last_name}
                      </h3>
                      <p style={{ textTransform: "none" }}>
                        {data?.customer_detail?.email}
                      </p>
                      <p>{data?.customer_detail?.phone_no}</p>
                    </ClientName>
                  </ClientInfoWrapper>
                  {/* <div className="clientImages">
                    <img src="" alt="" />
                  </div> */}
                </DetaiLContent>

                <ClientContent>
                  <div style={{ borderBottom: "1px solid #e0e0e0" }}>
                    <Header>
                      <h3>Account Information</h3>
                      <div className="actionBtns">
                        <div
                          className="downloadBtn"
                          onClick={pdfDownloadHandler}
                        >
                          <img src={downloadCloud} alt="" />
                          <p>Download</p>
                        </div>
                        {!editData?.staff_status ? (
                          <div
                            className="addAdminBtn"
                            onClick={addAdminHandler}
                          >
                            <img src={addIcon} alt="" />
                            <p>Add User as Admin</p>
                          </div>
                        ) : (
                          <div
                            className="removeAdminBtn"
                            onClick={removeFromAdminHnalder}
                          >
                            <AiOutlineStop />
                            <p>Remove from Admin</p>
                          </div>
                        )}
                      </div>
                    </Header>
                  </div>
                  <OuterContent>
                    <ContentInfo>
                      <div className="inputFields">
                        <p>Username</p>
                        <input
                          type="text"
                          value={`${data?.customer_detail?.username}`}
                          disabled
                        />
                      </div>
                      <div className="inputFields">
                        <p>Customer ID</p>
                        <input
                          type="text"
                          value={data?.customer_detail?.customer_id}
                          disabled
                        />
                      </div>
                      <div className="inputFields">
                        <p>Date Of Birth</p>
                        <input type="text" value={formattedDOB} disabled />
                      </div>
                      <div className="inputFields">
                        <p>BVN</p>
                        <input type="text" value={data?.bvn_number} disabled />
                      </div>
                      <div className="inputFields">
                        <p>Gender</p>
                        <input
                          type="text"
                          value={data?.customer_detail?.gender}
                          disabled
                        />
                      </div>
                      <div className="inputFields">
                        <p>Date Joined</p>
                        <input
                          type="text"
                          value={moment(data?.created_on).format("L")}
                          disabled
                        />
                      </div>
                      <div style={{position:'relative'}} className="inputFields">
                        <p>Limit Per Transfer</p>
                        <span style={{position:'absolute',marginLeft:'3px', marginTop:'12px'}} className="naira">₦</span>
                        <input
                          type="text"
                          placeholder={data?.limits?.transfer_limit}
                          value={editData.transfer_limit}
                          onChange={trsfLimitHandler}
                        />
                      </div>
                      <div style={{position:'relative'}} className="inputFields">
                        <p>Daily Transfer Limit</p>
                        <span style={{position:'absolute',marginLeft:'3px', marginTop:'12px'}} className="naira">₦</span>
                        <input
                          type="text"
                          placeholder={data?.limits?.daily_limit}
                          value={editData.daily_limit}
                          onChange={dailyLimitHandler}
                        />
                      </div>
                      <div className="inputFields">
                        <p>Accounts</p>
                        <input
                          type="text"
                          value={data?.accounts[0].account_no}
                          disabled
                        />
                      </div>
                      <div className="inputFields">
                        <p>Phone Number</p>
                        <span className="edit"><Edit onClick={() => settingUpdate()}/></span>
                        <input
                          style={{position:'relative'}}
                          type="number"
                          value={editData.phone_number}
                          onChange={(e) => {
                            if (e.target.value.length <= 11) {
                              setEditData((prev) => {
                                return {
                                  ...prev,
                                  phone_number: e.target.value,
                                };
                              });
                            } else {
                              return;
                            }
                          }}
                          disabled={update}
                        />
                      </div>
                      <div className="inputFields">
                          <p>Tier</p>
                          <select  onChange={(e) => TierChange(e)}  className='select'>
                          {/* <option value='' disabled selected>{data?.limits?.tier}</option> */}
                          {datatier?.map((item) => (
                            <option key={item.id} value={item.id} selected={data?.limits?.tier === item.tier}>{item.tier}</option>
                          ))}
                          </select>
                      </div>
                      <div className="inputFields">
                        <p>Lock Status</p>
                        <input
                          type="text"
                          value={data?.active === false ? "Locked" : "Unlocked"}
                          disabled
                        />
                      </div>
                    </ContentInfo>
                    <LockUnlock>
                      {data?.active ? (
                        <div
                          className="lockUnlockBtn"
                          onClick={lockAccountHandler}
                        >
                          <AiFillLock />
                          <p>Lock Account</p>
                        </div>
                      ) : (
                        <div
                          className="lockUnlockBtn"
                          onClick={unlockAccountHandler}
                        >
                          <AiFillUnlock />
                          <p>Unlock Account</p>
                        </div>
                      )}
                    </LockUnlock>
                    {
                    update
                    ?
                    ""
                    :
                    <SaveButtonWrapper>
                      <button onClick={fireChangesHandler}>Save Changes</button>
                    </SaveButtonWrapper>
                   }
                   {
                    updateLimit
                    ?
                    ""
                    :
                    <SaveButtonWrapper>
                      <button onClick={fireChangesHandler}>Save Changes</button>
                    </SaveButtonWrapper>
                   }
                  </OuterContent>
                </ClientContent>
              </Content>
            </PageContainer>
          )}
        </>
      </Wrapper>
    </div>
  );
};

export default Home;

const OuterContent = styled.div``;
const LockUnlock = styled.div`
  display: flex;
  padding: 10px 20px;
  flex-direction: row-reverse;
  justify-content: space-between;

  .lockUnlockBtn {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #ff0000b3;
    padding: 7px 6px;
    border-radius: 4px;
    cursor: pointer;

    svg {
      font-size: 14px;
      color: tomato;
    }
    p {
      font-size: 12px;
      color: tomato;
    }
  }
`;

const SaveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;

  button {
    background: #d22f31;
    color: #fff;
    border: none;
    padding: 8px 15px;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
  }
`;
