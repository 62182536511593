import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../../config/config";
import styled from "styled-components";
import Search from "../../../../reusables/table-search/Search";
import addIcon from "../../../../assets/icons/add-icon2.svg";
import { IoArrowBackCircle } from "react-icons/io5";
import dp from "../../../../assets/images/dp_fallback.svg";
import camera from "../../../../assets/icons/camera.svg";
import ReusableTable from "../../../../reusables/table/ReusableTable";
import { TableWrapper } from "../../Customers/Home";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import Skeleton from "react-loading-skeleton";

const Home = () => {
  const navigate = useNavigate();

  const columns = [
    { field: "no", header: "No" },
    { field: "name", header: "First Name" },
    { field: "lName", header: "Last Name" },
    { field: "gender", header: "Level" },
    { field: "email", header: "Email" },
    { field: "date", header: "Phone Number" },
    { field: "number", header: "BVN" },
    { field: "action", header: "Action" },
  ];

  const institutionId = JSON.parse(sessionStorage.getItem("institution_id"));
  const token = JSON.parse(sessionStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState();
  const [addSignatoryModal, setAddSignatoryModal] = useState(false);
  const [isSignatoryCreated, setIsSignatoryCreated] = useState(false);

  const getInstitutionById = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/institution/${institutionId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      //console.log(data);
      setLoading(false);
      setMyData(data);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getInstitutionById();
  }, [institutionId, isSignatoryCreated]);

  return (
    <Wrapper>
      <PageContainer>
        <div className="head">
          <h3>Institution</h3>
          <Search />
        </div>
        <div className="addInstitute">
          <IoArrowBackCircle
            size={25}
            style={{ cursor: "pointer", color: "#d22f31" }}
            onClick={() => {
              navigate("/corporate-banking");
            }}
          />
          <button
            onClick={() => {
              setAddSignatoryModal(true);
            }}
          >
            <img src={addIcon} alt="" />
            Add Signatories
          </button>
        </div>
        {loading ? (
          <Skeleton height={"200px"} width={"500px"} />
        ) : (
          <ClientInfoWrapper>
            <ClientImageContainer>
              <img style={{ width: "130px" }} src={dp} alt="" />
              <span className="camera-icons">
                <img src={camera} alt="" />
              </span>
            </ClientImageContainer>

            <ClientName>
              <h3>{myData?.name}</h3>
              <p style={{ textTransform: "none" }}>#{myData?.account_no}</p>
              <p>{myData?.address}</p>
            </ClientName>
          </ClientInfoWrapper>
        )}

        {loading ? (
          <Skeleton
            height={"600px"}
            width={"100%"}
            style={{ marginTop: "10px" }}
          />
        ) : (
          <TableWrapper>
            <ReusableTable
              type="institution"
              data={myData?.mandates}
              columns={columns}
              init={1}
            />
          </TableWrapper>
        )}
      </PageContainer>
      {addSignatoryModal && (
        <Modal
          setShow={setAddSignatoryModal}
          name={myData?.name}
          acctNum={myData?.account_no}
          customerID={myData?.customerID}
          address={myData?.address}
          shortcode={myData?.code}
          myInstitutionId={institutionId}
          isSignatoryCreated={isSignatoryCreated}
          setIsSignatoryCreated={setIsSignatoryCreated}
        />
      )}
    </Wrapper>
  );
};

export default Home;

const Wrapper = styled.div`
  padding: 0.5rem 2rem;
`;

const PageContainer = styled.div`
  padding-bottom: 30px;
  border-radius: 10px;
  background: #fff;
  position: relative;

  .head {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .addInstitute {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;

    button {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #d22f31;
      border: none;
      outline: none;
      background-color: transparent;
      border: 1px solid #d22f31;
      border-radius: 4px;
      padding: 10px;
      cursor: pointer;

      img {
        height: 20px;
      }
    }
  }
`;

const ClientInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 30px;
  margin-top: 20px;
`;
const ClientImageContainer = styled.div`
  position: relative;
  .camera-icons {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #d22f31;
    display: flex;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
    }
  }

  .client-dp {
    width: 100px;
  }
`;

const ClientName = styled.div`
  margin-top: 10px !important;
  text-transform: capitalize;
  h3 {
    margin-bottom: 10px !important;
  }
  p {
    margin-bottom: 10px !important;
  }
`;
