import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { IoArrowBackCircle } from "react-icons/io5";
import dp from "../../../../../assets/images/dp_fallback.svg";
import camera from "../../../../../assets/icons/camera.svg";
import { BASE_URL } from "../../../../../config/config";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const Home = () => {
  const navigate = useNavigate();
  const signatoryId = JSON.parse(sessionStorage.getItem("signatoryID"));
  const token = JSON.parse(sessionStorage.getItem("token"));

  const [loading, setLoading] = useState(false);
  const [signatory, setSignatory] = useState();
  const [signatoryStatus, setSighnatoryStatus] = useState(null);
  const [isStatusChanged, setIsStatusChanged] = useState(false);

  const getSignatory = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/mandate/${signatoryId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      //console.log(data);
      setLoading(false);
      setSignatory(data);
      setSighnatoryStatus(data?.active);
    } catch (error) {
      //console.log(error);
    }
  };
  const changeSignatoryStatus = async (status) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/mandate/${signatoryId}/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ active: status }),
        }
      );
      const data = await response.json();
      //console.log(data);
      setLoading(false);
      setSignatory(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const disableSignatoryHandler = () => {
    setSighnatoryStatus(false);
    changeSignatoryStatus(false);
    setIsStatusChanged(!isStatusChanged);
    window.location.reload();
  };
  const enableSignatoryHandler = () => {
    setSighnatoryStatus(true);
    changeSignatoryStatus(true);
    setIsStatusChanged(!isStatusChanged);
    window.location.reload();
  };

  useEffect(() => {
    getSignatory();

    return () => {
      //console.log("change");
    };
  }, [signatoryId, isStatusChanged]);
  return (
    <Wrapper>
      <PageContainer>
        <p>Signatories Details</p>
        <IoArrowBackCircle
          size={25}
          className="backIcon"
          onClick={() => {
            window.location.assign("./corporate-banking/institution");
          }}
        />

        {loading ? (
          <Skeleton
            width={"400px"}
            height={"200px"}
            style={{ marginTop: "20px" }}
          />
        ) : (
          <ClientInfoWrapper>
            <ClientImageContainer>
              <img style={{ width: "130px" }} src={dp} alt="" />
              <span className="camera-icons">
                <img src={camera} alt="" />
              </span>
            </ClientImageContainer>
            <ClientName>
              <h3>
                {signatory?.first_name} {signatory?.last_name}
              </h3>
              <p style={{ textTransform: "none" }}>{signatory?.phone_number}</p>
              <p style={{ textTransform: "lowercase" }}>{signatory?.email}</p>
            </ClientName>
          </ClientInfoWrapper>
        )}

        <ClientInformation>
          {loading ? (
            <Skeleton
              height={"300px"}
              width={"900px"}
              style={{ marginTop: "20px" }}
            />
          ) : (
            <>
              <div className="input">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  disabled={true}
                  value={signatory?.first_name}
                />
              </div>
              <div className="input">
                <label htmlFor="firstName">Last Name</label>
                <input
                  type="text"
                  disabled={true}
                  value={signatory?.last_name}
                />
              </div>
              <div className="input">
                <label htmlFor="firstName">Phone Number</label>
                <input
                  type="text"
                  disabled={true}
                  value={signatory?.phone_number}
                />
              </div>
              <div className="input">
                <label htmlFor="firstName">BVN</label>
                <input type="text" disabled={true} value={signatory?.bvn} />
              </div>
              <div className="input">
                <label htmlFor="firstName">Level</label>
                <input type="text" disabled={true} value={signatory?.level} />
              </div>
              <div className="input">
                <label htmlFor="firstName">Customer ID</label>
                <input type="text" disabled={true} value={signatory?.id} />
              </div>{" "}
            </>
          )}
        </ClientInformation>

        <div className="statusBtn">
          <button
            className="disable"
            style={{ display: `${signatory?.active === true ? "block" : ""}` }}
            onClick={disableSignatoryHandler}
          >
            Disable
          </button>
          <button
            style={{ display: `${signatory?.active === false ? "block" : ""}` }}
            onClick={enableSignatoryHandler}
          >
            Enable
          </button>
        </div>
      </PageContainer>
    </Wrapper>
  );
};

export default Home;

const Wrapper = styled.div`
  padding: 0.5rem 2rem;
  margin-top: 10px;
`;

const PageContainer = styled.div`
  padding-bottom: 30px;
  border-radius: 10px;
  background: #fff;
  position: relative;

  p {
    padding-left: 25px;
    font-size: 28px;
    font-weight: 600;
  }

  .backIcon {
    margin-top: 30px;
    padding-left: 30px;
    margin-bottom: 20px;
    cursor: pointer;
    color: #d22f31;
  }

  .statusBtn {
    padding: 0 30px;
    display: flex;
    button {
      background-color: #d22f31;
      width: 100px;
      height: 30px;
      border: none;
      border-radius: 5px;
      color: #fff;
      outline: none;
      cursor: pointer;
      display: none;

      &:active,
      &:focus {
        outline: none;
      }
    }

    .disable {
      background-color: tomato;
      color: #fff;
    }
  }
`;

const ClientInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 30px;
  margin-top: 20px;
`;
const ClientImageContainer = styled.div`
  position: relative;
  .camera-icons {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #d22f31;
    display: flex;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
    }
  }

  .client-dp {
    width: 100px;
  }
`;

const ClientName = styled.div`
  margin-top: 10px !important;
  text-transform: capitalize;
  h3 {
    margin-bottom: 10px !important;
    font-size: 25px;
  }
  p {
    margin-bottom: 10px !important;
    font-size: 16px;
    padding-left: 0;
    font-weight: 400;
  }
`;

const ClientInformation = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 30px;
  margin-top: 30px;

  .input {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 15px;

    label {
      font-weight: 600;
    }
    input {
      margin-bottom: 8px;
      height: 37px;
      padding: 0 10px;
      width: 80%;
      text-transform: capitalize;
    }
  }
`;
