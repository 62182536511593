import React, { useEffect, useState } from "react";
import { Wrapper, PageContainer } from "../Customers/Home";
import styled from "styled-components";
import { BASE_URL } from "config/config";
import Skeleton from "react-loading-skeleton";
import toast from "react-hot-toast";
import {ReactComponent as Edit} from '../../../assets/images/edit.svg';

const Home = () => {
  const token = JSON.parse(sessionStorage.getItem("token"));
  const [loading, setLoading] = useState(null);
  const [data, setData] = useState([]);
  const [daily, setDaily] = useState('');
  const [transfer, setTransfer] = useState('');
  const [update, setUpdate] = useState(true)
  const [updateLimit, setUpdateLimit] = useState(true)

  useEffect(() => {
    const getTierAccounts = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/account-tier`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let data = await response.json();
        console.log(data)
        if (data?.count === 0) {
          // setStartCount(0);
          // setEndCount(0);
        }
        setData(data);
        setDaily(data[1]?.daily_limit)
        setTransfer(data[1]?.transfer_limit)
        setLoading(false);
      } catch (e) {
        //console.log(e);
      }
    };
    getTierAccounts()

  }, [])

  const settingUpdate = () => {
    setUpdate((prev) => !prev)
    setUpdateLimit(true)

  }

  const settingUpdateLimit = () => {
    setUpdateLimit((prev) => !prev)
    setUpdate(true)
    
  }

  const SaveChanges = async () => {
    
      try {
        setLoading(true);
        console.log(daily)
        console.log(transfer)
        const cleanedDaily = daily.replace(/,/g, "");
        const cleanedTransfer = transfer.replace(/,/g, "");
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/account-tier/${data[1]?.id}/`,
          {
            method: "PUT",
            body: JSON.stringify({
              daily_limit: cleanedDaily,
              transfer_limit: cleanedTransfer
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let result = await response.json();
        console.log(result)
        toast.success(result.detail)
        setUpdate(true)
        setUpdateLimit(true)
        const getTierAccounts = async () => {
          try {
            setLoading(true);
            const response = await fetch(
              `${process.env.REACT_APP_BASE_URL}/api/account-tier`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            let data = await response.json();
            console.log(data)
            if (data?.count === 0) {
              // setStartCount(0);
              // setEndCount(0);
            }
            setData(data);
            setDaily(data[1]?.daily_limit)
            setTransfer(data[1]?.transfer_limit)
            setLoading(false);
          } catch (e) {
            //console.log(e);
          }
        };
        getTierAccounts()
      } catch (e) {
        //console.log(e);
      }
  }

  const formatNumber = (inputValue) => {
    const numericValue = Number(inputValue?.replace(/,/g, "") ?? "");
    if (!isNaN(numericValue)) {
      return numericValue.toLocaleString();
    }
    return inputValue;
  };

  return (
    <Wrapper>
      <PageContainer>
        <div className="table-container">
          <div
            className="table-head"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3>Account Tier Settings</h3>
            {/* <Search
              changeFunction={searchChangeHandler}
              value={searchParams}
              searchHandler={onSearchHandler}
              keyDownHandler={onKeyPressHandler}
            /> */}
          </div>
        </div>

        {
           loading ? (
            <Skeleton height={"400px"} />
           ) : (
            // eslint-disable-next-line array-callback-return
            <>
            <TierContainer>
            <div className="heading">
              <h3>Tier 1</h3>
            </div>
            <div className="body">
              <div className="inputSect">
                <label htmlFor="">Limit Per Transfer</label>
                <input type="text" disabled value={`₦${formatNumber(data[0]?.transfer_limit)}`} placeholder={data[0]?.transfer_limit ? `₦${data[0]?.transfer_limit}` :  ''}  className="formInput" />
              </div>
              <div className="inputSect">
                <label htmlFor="">Daily Transaction Limit</label>
                <input type="text" disabled value={`₦${formatNumber(data[0]?.daily_limit)}`} placeholder={data[0]?.daily_limit ? `₦${data[0]?.daily_limit}` : ''} className="formInput" />
              </div>
            </div>
          </TierContainer>
          <TierContainer>
            <div className="heading">
              <h3>Tier 3</h3>
            </div>
            <div className="body">
              <div className="inputSect">
                <label htmlFor="">Limit Per Transfer</label>
                <div className="container">
                <span className="naira">₦</span>
                <input type="text" disabled={update} onChange={(e) => setTransfer(e.target.value)} value={`${formatNumber(transfer)}`} placeholder={data[1]?.transfer_limit ? `₦${data[1]?.transfer_limit}` : ''} className="formInput" />
                <Edit className="editing" style={{cursor:'pointer'}} onClick={() => settingUpdate()}/>
                </div>
              </div>
              <div className="inputSect">
                <label htmlFor="">Daily Transaction Limit</label>
                <div className="container">
                <span className="naira">₦</span>
                <input type="text" disabled={updateLimit} onChange={(e) => setDaily(e.target.value)} value={`${formatNumber(daily)}`} placeholder={data[1]?.daily_limit ? `₦${data[1]?.daily_limit}` : ''} className="formInput" />
                <Edit className="editing" style={{cursor:'pointer'}} onClick={() => settingUpdateLimit()}/>
                </div>
              </div>
            </div>
            {
              update
              ?
              ""
              :
              <div className="btnContainer">
              <button onClick={() => SaveChanges()}>Save Changes</button>
              </div>
            }
             {
              updateLimit
              ?
              ""
              :
              <div className="btnContainer">
              <button onClick={() => SaveChanges()}>Save Changes</button>
              </div>
            }
          </TierContainer>
          </>

           )
        }
        
      </PageContainer>
    </Wrapper>
  );
};

export default Home;

const TierContainer = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 1rem;
  margin: 3rem 2rem;
  

  .heading {
    padding: 2rem;
    border-bottom: 1px solid #e0e0e0;
    h3 {
      font-size: 1rem;
      font-weight: 500;
      color: #333333;
    }
  }
  .body {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15%;
    .inputSect {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      flex: 1;

      label {
        font-size: 0.9rem;
        font-weight: 700;
        color: #333333;
      }
      .container{
        display: flex;
        flex-direction: row;
        gap: 5px;
        position: relative;
        .editing{
          position: absolute;
          margin-left: 90%;
        }
        .naira{
          position: absolute;
          margin-top: 9px;
          margin-left: 6px;
        }
        .formInput {
          height: 34px;
          border-radius: 6px;
          border: 1px solid #d9d9d9;
          padding: 0 0.9rem;
          outline: none;
          width: 100%;
        }
      }

      .formInput {
          height: 34px;
          border-radius: 6px;
          border: 1px solid #d9d9d9;
          padding: 0 0.6rem;
          outline: none;
          width: 100%;
        }
    }
  }
  .btnContainer {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    button {
      border-radius: 6px;
      height: 34px;
      padding: 0 10px;
      border: none;
      outline: none;
      background-color: #d22f31;
      color: #fff;
      cursor: pointer;
    }
  }
`;
