import React, { useState, useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import * as dotenv from "dotenv";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login, PageNotFound } from "./view/Auth";
import {
  Airtime,
  Overview,
  Customers,
  Requests,
  CIT,
  Others,
  Data,
  CableTV,
  Electricty,
  PendingReview,
  ApprovedReview,
  CustomerReview,
  CorporateBanking,
  TmsassOverview,
  InstitutionView,
  SignatoryView,
  Settings,
  Betting,
  Insurance
} from "./view/Dashboard";
import { Toaster } from "react-hot-toast";
import { ProtectedRoute, InactivityTimeout } from "./hooks";

import { SkeletonTheme } from "react-loading-skeleton";

function App() {
  dotenv.config();

  return (
    <>
      {/* <InactivityTimeout /> */}

      <SkeletonTheme>
        <Toaster position="top-center" reverseOrder={false} />
        <Router>
          <Routes>
            <Route element={<InactivityTimeout />}>
              <Route index element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<Overview />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/requests" element={<Requests />} />
                <Route path="/kc-mfb-trsf" element={<CIT />} />
                <Route path="/others" element={<Others />} />
                <Route path="/airtime" element={<Airtime />} />
                <Route path="/data" element={<Data />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/cable" element={<CableTV />} />
                <Route path="/electricity" element={<Electricty />} />
                <Route path="/betting" element={<Betting />} />
                <Route path="/insurance" element={<Insurance />} />
                <Route path="/pending-review" element={<PendingReview />} />
                <Route path="/customer-review" element={<CustomerReview />} />
                <Route path="/approved-review" element={<ApprovedReview />} />
                <Route path="/tmsass-overview" element={<TmsassOverview />} />
                <Route
                  path="/corporate-banking/institution"
                  element={<InstitutionView />}
                />
                <Route
                  path="/institiution-signatory"
                  element={<SignatoryView />}
                />
                <Route
                  path="/corporate-banking"
                  element={<CorporateBanking />}
                />

                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Route>
          </Routes>
        </Router>
        {/* </Timeout> */}
      </SkeletonTheme>
    </>
  );
}

export default App;
