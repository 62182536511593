import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Wrapper, PageContainer } from "../../../Customers/Home";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import camera from "../../../../../assets/icons/camera.svg";

import {
  ClientInfoWrapper,
  ClientImageContainer,
  ClientName,
} from "../../pending/review/Home";
import downloadCloud from "../../../../../assets/icons/download-cloud.svg";
import { BASE_URL } from "../../../../../config/config";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
const Home = () => {
  const token = JSON.parse(sessionStorage.getItem("token"));
  const approvedCustomer = JSON.parse(
    sessionStorage.getItem("approvedCustomerId")
  );
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const html2pdf = require("html2pdf.js/dist/html2pdf.bundle");

  const pdfDownloadHandler = () => {
    const invoice = window?.document?.getElementById("document_to_print");
    const opt = {
      margin: 1,
      filename: "Approved Info.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
    };
    html2pdf(invoice, opt);
  };

  useEffect(() => {
    const getApprovedCustomer = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/1/account-request/${approvedCustomer}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let data = await response.json();
        setData(data);
        setLoading(false);
      } catch (error) {
        //console.log(error);
      }
    };
    getApprovedCustomer();
  }, [approvedCustomer]);

  return (
    <Wrapper id="document_to_print">
      <PageContainer style={{ padding: "10px" }}>
        {loading ? (
          <Skeleton
            width={"100%"}
            height={"700px"}
            style={{ marginTop: "20px" }}
          />
        ) : (
          <Content id="pdfelement">
            <Link to="/requests">
              <BsFillArrowLeftCircleFill style={{ color: "#d22f31" }} />
            </Link>
            <DetaiLContent>
              <ClientInfoWrapper>
                <ClientImageContainer>
                  <img src={data?.image} alt="" className="client-dp" />
                  <span className="camera-icons">
                    <img src={camera} alt="" />
                  </span>
                </ClientImageContainer>
                <ClientName>
                  <h3>
                    {data?.first_name} {data?.last_name}
                  </h3>
                  <p style={{ textTransform: "none" }}>{data?.email}</p>
                  <p>{data?.phone_no}</p>
                </ClientName>
              </ClientInfoWrapper>
              <ClientImagesWrapper>
                <img src={data?.valid_id} alt="" className="client-dp" />
                <img src={data?.utility} alt="" className="client-dp" />
                <img src={data?.signature} alt="" className="client-dp" />
              </ClientImagesWrapper>
            </DetaiLContent>

            <ClientContent>
              <div style={{ borderBottom: "1px solid #e0e0e0" }}>
                <Header>
                  <h3>Account Information</h3>
                  <div className="actionBtns" id="downloadPDF">
                    <div className="downloadBtn" onClick={pdfDownloadHandler}>
                      <img src={downloadCloud} alt="" />
                      <p>Download</p>
                    </div>
                  </div>
                </Header>
              </div>
              <ContentInfo>
                <div className="inputFields">
                  <p>Full Name</p>
                  <input
                    type="text"
                    value={`${data?.first_name} ${data?.last_name}`}
                    disabled
                  />
                </div>
                <div className="inputFields">
                  <p>Email Address</p>
                  <input type="text" value={data?.email} disabled />
                </div>
                <div className="inputFields">
                  <p>Address</p>
                  <input type="text" value={data?.address} disabled />
                </div>
                <div className="inputFields">
                  <p>BVN</p>
                  <input type="text" value={data?.bvn} disabled />
                </div>
                <div className="inputFields">
                  <p>Gender</p>
                  <input type="text" value={data?.gender} disabled />
                </div>
                <div className="inputFields">
                  <p>NIN</p>
                  <input type="text" value={data?.nin} disabled />
                </div>
                <div className="inputFields">
                  <p>Phone Number</p>
                  <input type="text" value={data?.phone_no} disabled />
                </div>
                <div className="inputFields">
                  <p>Date Of Birth</p>
                  <input type="text" value={data?.dob} disabled />
                </div>
                <div className="inputFields">
                  <p>Request Status</p>
                  <input type="text" value={data?.status} disabled />
                </div>
                <div className="inputFields">
                  <p>Date Joined</p>
                  <input
                    type="text"
                    value={moment(data?.updated_on).format("lll")}
                    disabled
                  />
                </div>
              </ContentInfo>
            </ClientContent>
          </Content>
        )}
      </PageContainer>
    </Wrapper>
  );
};

export default Home;

export const Content = styled.div`
  svg {
    font-size: 20px;
  }
  a {
    text-decoration: none;
    color: unset;
  }
`;
export const DetaiLContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .clientImages {
  }
`;
const ClientImagesWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;

  img {
    height: 150px;
    width: 130px;
  }
`;
export const ClientContent = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  margin-top: 20px;
`;

export const Header = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .actionBtns {
    display: flex;
    gap: 20px;

    .addAdminBtn {
      background: transparent;
      border: 1px solid #d22f31;
      border-radius: 4px;
      color: #d22f31;
      display: flex;
      justify-content: space-around;
      gap: 15px;
      padding: 7px 25px;
      cursor: pointer;

      p {
        font-size: 14px;
        margin-top: 3px !important;
      }
      img {
        width: 15px;
      }
    }
    .removeAdminBtn {
      background: transparent;
      border: 1px solid tomato;
      border-radius: 4px;
      color: tomato;
      display: flex;
      justify-content: space-around;
      gap: 15px;
      padding: 7px 25px;
      cursor: pointer;

      p {
        font-size: 14px;
        margin-top: 3px !important;
      }
      img {
        width: 15px;
      }
    }
    .downloadBtn {
      background: #d22f31;
      color: #fff;
      display: flex;
      justify-content: space-around;
      gap: 15px;
      padding: 7px 25px;
      border-radius: 4px;
      cursor: pointer;

      p {
        font-size: 14px;
        margin-top: 3px !important;
      }
    }
  }
`;
export const ContentInfo = styled.div`
  padding: 15px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  .edit{
    position: absolute;
    cursor: pointer;
    z-index: 1000;
    margin-left: 35%;
    
  }
  .inputFields {
    margin-bottom: 20px;
    p {
      font-size: 13px;
      font-weight: 500;
    }
    input {
      margin-top: 5px;
      width: 80%;
      padding: 0 12px;
      height: 30px;
    }
    select{
      margin-top: 5px;
      width: 85%;
      padding: 0 10px;
      height: 36px;
      border: 1px solid #a09b9b;
      outline: none;
      border-radius: 4px;
      color: #a09b9b;

    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;
