import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Search from "../../../../reusables/table-search/Search";
import { Wrapper } from "../../Customers/Home";
import { PageContainer } from "../../Customers/Home";
import { FilterContainer } from "../../Customers/Home";
import { TableWrapper } from "../../Customers/Home";
import { PaginationWrapper } from "../../Customers/Home";
import { PaginationBtnWrapper } from "../../Customers/Home";
import downloadCloud from "../../../../assets/icons/download-cloud.svg";
import ReusableTable from "../../../../reusables/table/ReusableTable";
import Skeleton from "react-loading-skeleton";

const Home = () => {
  const token = JSON.parse(sessionStorage.getItem("token"));
  const [isApproved, setIsApproved] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [data, setData] = useState(null);
  const [dataOthers, setDataOthers] = useState(null);
  const [loading, setLoading] = useState(null);
  const [init, setInit] = useState(1);
  const [initOthers, setInitOthers] = useState(1);
  const [startPoint, setStartPoint] = useState(0);
  const [endPoint, setEndPoint] = useState(5);
  const [startPointOthers, setStartPointOthers] = useState(0);
  const [endPointOthers, setEndPointOthers] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageOthers, setCurrentPageOthers] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [endNumber, setEndNumber] = useState(indexOfLastPost);
  const [startCount, setStartCount] = useState(currentPage);
  const [endCount, setEndCount] = useState(currentPage * 10);
  const [startCountOthers, setStartCountOthers] = useState(currentPageOthers);
  const [endCountOthers, setEndCountOthers] = useState(currentPageOthers * 10);
  const [searchParams, setSearchParams] = useState("");
  const [searchParamsOthers, setSearchParamsOthers] = useState("");
  const [searched, setSearched] = useState(false);

  let num = 1;

  const dataLength = data?.detail?.count;
  const endPage = Math.ceil(dataLength / postsPerPage);
  let myData = data?.detail?.results;
  let myDataOthers = dataOthers?.detail?.results;

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(dataLength / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const myPageNumbers = pageNumbers.slice(startPoint, endPoint);

  const columns = [
    { field: "no", header: "No" },
    { field: "date", header: "Date" },
    { field: "beneficiary", header: "Beneficiary Phone" },
    { field: "email", header: "Email Address" },
    { field: "insurance_type_id", header: "Insurance Type" },
    { field: "amount", header: "Amount(#)" },
    { field: "transId", header: "Trans. ID" },
    { field: "status", header: "Status" },
  ];

  const columnsothers = [
    { field: "no", header: "No" },
    { field: "date", header: "Date" },
    { field: "beneficiary", header: "Beneficiary Phone" },
    { field: "email", header: "Email Address" },
    { field: "insurance_type_id", header: "Insurance Type" },
    { field: "amount", header: "Amount(#)" },
    { field: "transId", header: "Trans. ID" },
    { field: "status", header: "Status" },
  ];

  const prevPageHandler = () => {
    if (currentPage === 1) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
    if (currentPage <= startPoint + 1) {
      setStartPoint(startPoint - 5);
      setEndPoint(endPoint - 5);
    }
    setStartCount(startCount - 10);
    setEndCount(endCount - 10);
    setInit(init - 10);
  };

  const prevPageHandlerOthers = () => {
    if (currentPageOthers === 1) {
      return;
    } else {
      setCurrentPageOthers(currentPageOthers - 1);
    }
    if (currentPageOthers <= startPointOthers + 1) {
      setStartPointOthers(startPointOthers - 5);
      setEndPointOthers(endPointOthers - 5);
    }
    setStartCountOthers(startCountOthers - 10);
    setEndCountOthers(endCountOthers - 10);
    setInitOthers(initOthers - 10);
  };
  const nextPageHandler = () => {
    if (currentPage >= endPage) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
    if (currentPage >= endPoint) {
      setStartPoint(startPoint + 5);
      setEndPoint(endPoint + 5);
    }
    setStartCount(startCount + 10);
    setEndCount(endCount + myData?.length);
    setInit(init + 10);
    num = num + 1;
  };

  const nextPageHandlerOthers = () => {
    if (currentPageOthers >= endPage) {
      return;
    } else {
      setCurrentPageOthers(currentPageOthers + 1);
    }
    if (currentPageOthers >= endPoint) {
      setStartPoint(startPointOthers + 5);
      setEndPointOthers(endPointOthers + 5);
    }
    setStartCountOthers(startCountOthers + 10);
    setEndCountOthers(endCountOthers + myDataOthers?.length);
    setInitOthers(initOthers + 10);
    num = num + 1;
  };

  const searchChangeHandler = (e) => {
    setLoading(true);
    setSearchParams(e.target.value.trim(""));
    setLoading(false);
    setSearched(false);
  };

  const searchChangeHandlerOthers = (e) => {
    setLoading(true);
    setSearchParamsOthers(e.target.value.trim(""));
    setLoading(false);
    setSearched(false);
  };

  useEffect(() => {
    if (myData?.length < postsPerPage) {
      setEndCount(endCount + (myData?.length - 10));
    }

    if (endPage > currentPage) {
      setEndCount(currentPage * 10);
    }
  }, [searchParams, currentPage, searchParamsOthers, currentPageOthers]);

  useEffect(() => {
    const getApprovedRequestsVehicle = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/1/bill-payment?search=${searchParams}&bill_type=vehicle_insurance&page=${currentPage}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let data = await response.json();
        if (data?.detail?.count === 0) {
          setStartCount(0);
          setEndCount(0);
        }
        setData(data);
        console.log(data);
        setLoading(false);
      } catch (e) {
        //console.log(e);
      }
    };
    const getApprovedRequestsOthers = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/1/bill-payment?search=${searchParamsOthers}&bill_type=other_insurance&page=${currentPageOthers}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let data = await response.json();
        if (data?.detail?.count === 0) {
          setStartCountOthers(0);
          setEndCountOthers(0);
        }
        setDataOthers(data);
        console.log(data);
        setLoading(false);
      } catch (e) {
        //console.log(e);
      }
    };

    getApprovedRequestsVehicle();
    getApprovedRequestsOthers();
  }, [currentPage, currentPageOthers]);
  //console.log(data);

  const csvExportHandler = () => {
    const csvTableHead = [
      "Date",
      "Beneficiary Name",
      "Email",
      "Insurance_Type_ID",
      "Amount",
      "Trans. ID",
      "Status",
    ];

    const myCsvData = JSON.parse(JSON.stringify(myData));
    let formattedData = [];
    for (let i = 0; i < myCsvData.length; i++) {
      // let customer = { ...myCsvData[i].customer };
      let dates = myCsvData[i].created_on;
      let benName = myCsvData[i].account_no;
      let email = myCsvData[i].email;
      let insuranceType = myCsvData[i].insurance_type_id;
      let amount = myCsvData[i].amount;
      let reference = myCsvData[i].transaction_id;
      let status = myCsvData[i].status;

      const newBenName = benName.replace(/,/g, "");

      const csvData = {
        dates,
        benName,
        email,
        insuranceType,
        amount,
        reference,
        status,
      };
      //console.log(csvData);
      formattedData.push(csvData);
    }

    //console.log(formattedData);
    const objValues = formattedData.map((item) =>
      Object.values(item).toString()
    );
    const csv = [csvTableHead, ...Object.values(objValues)].join("\n");
    //console.log(csv);
    const blob = new Blob([csv], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    //console.log(a);
    a.download = "vehicle_insurance.csv";
    a.href = url;
    a.click();
    a.remove();
    URL.revokeObjectURL(blob);
  };

  const csvExportHandlerOthers = () => {
    const csvTableHead = [
      "Date",
      "Beneficiary Number",
      "Email",
      "Insurance_Type",
      "Amount",
      "Trans. ID",
      "Status",
    ];

    const myCsvData = JSON.parse(JSON.stringify(myDataOthers));
    let formattedData = [];
    for (let i = 0; i < myCsvData.length; i++) {
      // let customer = { ...myCsvData[i].customer };
      let dates = myCsvData[i].created_on;
      let benName = myCsvData[i].account_no;
      let email = myCsvData[i].email;
      let insuranceType = myCsvData[i].insurance_type;
      let amount = myCsvData[i].amount;
      let reference = myCsvData[i].transaction_id;
      let status = myCsvData[i].status;

      const newBenName = benName.replace(/,/g, "");

      const csvData = {
        dates,
        benName,
        email,
        insuranceType,
        amount,
        reference,
        status,
      };
      //console.log(csvData);
      formattedData.push(csvData);
    }

    //console.log(formattedData);
    const objValues = formattedData.map((item) =>
      Object.values(item).toString()
    );
    const csv = [csvTableHead, ...Object.values(objValues)].join("\n");
    //console.log(csv);
    const blob = new Blob([csv], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    //console.log(a);
    a.download = "other_insurance.csv";
    a.href = url;
    a.click();
    a.remove();
    URL.revokeObjectURL(blob);
  };

  const onKeyPressHandler = (e) => {
    //console.log(e);
    if (e.code === "Backspace" && e.target.value.length <= 1) {
      setCurrentPage(1);
      const getApprovedRequestsVehicle = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/1/bill-payment?search=${searchParams}&bill_type=vehicle_insurance&page=${currentPage}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          let data = await response.json();
          if (data?.detail?.count === 0) {
            setStartCount(0);
            setEndCount(0);
          }
          setData(data);
          setLoading(false);
        } catch (e) {
          //console.log(e);
        }
      };

      getApprovedRequestsVehicle();
    }
  };

  const onKeyPressHandlerOthers = (e) => {
    //console.log(e);
    if (e.code === "Backspace" && e.target.value.length <= 1) {
      setCurrentPageOthers(1);
      const getApprovedRequestsOthers = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/1/bill-payment?search=${searchParamsOthers}&bill_type=other_insurance&page=${currentPageOthers}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          let data = await response.json();
          if (data?.detail?.count === 0) {
            setStartCountOthers(0);
            setEndCountOthers(0);
          }
          setDataOthers(data);
          setLoading(false);
        } catch (e) {
          //console.log(e);
        }
      };

      getApprovedRequestsOthers();
    }
  };

  const onSearchHandler = async () => {
    setSearched(true);
    setCurrentPage(1);
  const getApprovedRequestsVehicle = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/1/bill-payment?search=${searchParams}&bill_type=vehicle_insurance&page=${currentPage}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let data = await response.json();
      if (data?.detail?.count === 0) {
        setStartCount(0);
        setEndCount(0);
      }
      setData(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  getApprovedRequestsVehicle();

    // setSearchParams()
  };

  const onSearchHandlerOthers = async () => {
    setSearched(true);
    setCurrentPageOthers(1);
  const getApprovedRequestsOthers = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/1/bill-payment?search=${searchParamsOthers}&bill_type=other_insurance&page=${currentPage}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let data = await response.json();
      if (data?.detail?.count === 0) {
        setStartCountOthers(0);
        setEndCountOthers(0);
      }
      setDataOthers(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  getApprovedRequestsOthers();

    // setSearchParams()
  };

  const showApproveHandler = () => {
    setIsApproved(true);
    setIsPending(false);
    setSearchParamsOthers('')
    setSearchParams('')
  };
  const showPendingHandler = () => {
    setIsApproved(false);
    setIsPending(true);
    setSearchParams('')
    setSearchParamsOthers('')
  };

  return (
    <Wrapper>
      <PageContainer>
        <div className="table-container">
          <div
            className="table-head"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3>Insurance</h3>
            {
              isApproved ?
              <Search
              changeFunction={searchChangeHandler}
              value={searchParams}
              searchHandler={onSearchHandler}
              keyDownHandler={onKeyPressHandler}
            /> :
            <Search
              changeFunction={searchChangeHandlerOthers}
              value={searchParamsOthers}
              searchHandler={onSearchHandlerOthers}
              keyDownHandler={onKeyPressHandlerOthers}
            />
            }
          </div>
        </div>
         <RouteWrapper>
          <button
              onClick={showApproveHandler}
              className={isApproved ? "active" : ""}
            >
              Vehicle Insurance
            </button>
            <button
              onClick={showPendingHandler}
              className={isPending ? "active" : ""}
            >
              Other Insurance
            </button>
          </RouteWrapper>

        <FilterContainer>
          <div className="buttonWrapper">
            <img src={downloadCloud} alt="download" />
            {isApproved ? <button onClick={csvExportHandler}>Export Report</button>
            : <button onClick={csvExportHandlerOthers}>Export Report</button>}
          </div>
        </FilterContainer>

        {loading  ? (
          <Skeleton height={"400px"} />
        ) : isApproved ? (
          <TableWrapper>
            <ReusableTable
              init={init}
              type="vehicle_insurance"
              data={myData}
              columns={columns}
            />
            <PaginationWrapper>
              <h5>
                Showing {startCount} to {endCount} of {dataLength} Entries
              </h5>
              <PaginationBtnWrapper>
                <button onClick={prevPageHandler}>Previous</button>
                {myPageNumbers.map((number) => (
                  <div key={number} className="numbers">
                    <p
                      href="#"
                      style={{
                        background: currentPage === number ? "#ff45604a" : "",
                      }}
                      key={number}
                    >
                      {number}
                    </p>
                  </div>
                ))}
                <button onClick={nextPageHandler}>Next</button>
              </PaginationBtnWrapper>
            </PaginationWrapper>
          </TableWrapper>
        ) : isPending ? (
          <TableWrapper>
            <ReusableTable
              init={init}
              type="other_insurance"
              data={myDataOthers}
              columns={columnsothers}
            />
            <PaginationWrapper>
              <h5>
                Showing {startCountOthers} to {endCountOthers} of {dataLength} Entries
              </h5>
              <PaginationBtnWrapper>
                <button onClick={prevPageHandlerOthers}>Previous</button>
                {myPageNumbers.map((number) => (
                  <div key={number} className="numbers">
                    <p
                      href="#"
                      style={{
                        background: currentPageOthers === number ? "#ff45604a" : "",
                      }}
                      key={number}
                    >
                      {number}
                    </p>
                  </div>
                ))}
                <button onClick={nextPageHandlerOthers}>Next</button>
              </PaginationBtnWrapper>
            </PaginationWrapper>
          </TableWrapper>
        ) : '' }
      </PageContainer>
    </Wrapper>
  );
};

export default Home;


const RouteWrapper = styled.div`
  margin-block: 30px;
  border-bottom: 0.7px solid #e0e0e0;

  button {
    background: transparent;
    border: none;
    padding: 5px 40px;
    cursor: pointer;
    font-size: 13px;
    transition: 0.3s ease-in;

    &:hover {
      color: #d22f31;
    }
  }
  .active {
    color: #d22f31;
    border-bottom: 1px solid #d22f31;
  }
`;