import React, { useState, useEffect } from "react";
import styled from "styled-components";
import airtime from "../../../assets/icons/airtime-mobile.svg";
import electricity from "../../../assets/icons/electricity.svg";
import internet from "../../../assets/icons/internet-mobile.svg";
import cableTV from "../../../assets/icons/tv-mobile.svg";
import transactionsIcon from "../../../assets/images/transactions.svg";
import mobileUsersIcon from "../../../assets/images/mobile-users.svg";
import airtimeIcon from "../../../assets/images/airtime.svg";
import billsPayment from "../../../assets/images/bills-payment.svg";
import Bar from "./Graphicals/LineChart";
import Pie from "./Graphicals/PieChart";
import Table from "./Graphicals/Table";
import SearchInput from "../../../reusables/inputs/SearchInput";
import { BASE_URL } from "../../../config/config";
import Skeleton from "react-loading-skeleton";
import BillsCard from "components/BillsCard";
import BillsCard2 from "components/BillsCard2";
import indTxnImg from "assets/icons/paid.svg";
import corpTxnImg from "assets/icons/corptxn.svg";
import totTxnImg from "assets/icons/txntot.svg";
import airTotImg from "assets/icons/airtot.svg";
import usersImg from "assets/icons/users.svg";

const Home = () => {
  const token = JSON.parse(sessionStorage.getItem("token"));
  const customerFirstName = JSON.parse(
    sessionStorage.getItem("customerFirstName")
  );
  const customerLastName = JSON.parse(
    sessionStorage.getItem("customerLastName")
  );
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [localChart, setLocalChart] = useState();
  const [otherChart, setOtherChart] = useState();
  const [chartType, setChartType] = useState("weekly");
  const [products, setProducts] = useState();
  const [sales, setSales] = useState();

  const [products2, setProducts2] = useState();
  const [sales2, setSales2] = useState();
  const abortController = new AbortController();

  const bills = [
    { image: airtime, background: "#0D96F8", name: "Airtime" },
    { image: internet, background: "#7495E5", name: "Mobile Data" },
    { image: cableTV, background: "#916AFF", name: "Cable TV" },
    { image: electricity, background: "#FBB538", name: "Electricity" },
  ];

  useEffect(() => {
    setProducts(localChart?.yearly?.map((month) => month.year) || []);
    setSales(localChart?.yearly?.map((month) => month.amount) || []);

    setProducts2(otherChart?.yearly?.map((month) => month.year) || []);
    setSales2(otherChart?.yearly?.map((month) => month.amount) || []);
  }, [data]);

  useEffect(() => {
    const getDashboard = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api?bank_id=1`,
          {
            method: "GET",
            signal: abortController.signal,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let data = await response.json();
        setLocalChart(data?.local_transfer_chart);
        setOtherChart(data?.others_transfer_chart);
        setData(data || data);
        setLoading(false);
      } catch (e) {
        if (!abortController.signal.aborted) {
        }
        setLoading(false);
      }
    };

    getDashboard();

    return () => {
      abortController.abort();
    };
  }, []);

  const pieData = [
    data?.airtime_purchase_total,
    data?.data_purchase_total,
    data?.cable_tv_purchase_total,
    data?.electricity_purchase_total,
  ];

  return (
    <>
      <PageContainer>
        <h3 className="welcome">
          Welcome Back, {customerFirstName} {customerLastName}
        </h3>
        {loading ? (
          <>
            <SkeletonWrapper>
              <Skeleton
                height={"150px"}
                // width={"300px"}
                className="overviewSkeleton"
              />
              <Skeleton
                height={"150px"}
                // width={"300px"}
                className="overviewSkeleton"
              />
              <Skeleton
                height={"150px"}
                // width={"300px"}
                className="overviewSkeleton"
              />
              <Skeleton
                height={"150px"}
                // width={"300px"}
                className="overviewSkeleton"
              />
            </SkeletonWrapper>
            <SkeletonWrapper2>
              <Skeleton
                height={"150px"}
                // width={"300px"}
                className="overviewSkeleton"
              />
              <Skeleton
                height={"150px"}
                // width={"300px"}
                className="overviewSkeleton"
              />
            </SkeletonWrapper2>
          </>
        ) : (
          <>
            <BillsCardWrapper>
              <BillsCard color="#ED8C8C" backgroundColor="#F5F5F5">
                <div className="icon">
                  <img src={indTxnImg} alt="" />
                </div>
                <div>
                  <p className="cardName">Individual Transactions</p>

                  <p className="amount">
                    ₦
                    {data?.total_individual_transaction_amount?.toLocaleString()}
                  </p>
                  <p className="counts">
                    count: {data?.total_individual_transaction_count}
                  </p>
                </div>
              </BillsCard>
              <BillsCard color="#377DFF" backgroundColor="#F5F5F5">
                <div className="icon">
                  <img src={corpTxnImg} alt="" />
                </div>
                <div>
                  <p className="cardName">Corporate Transactions</p>

                  <p className="amount">
                    ₦
                    {data?.total_corporate_transaction_amount?.toLocaleString()}
                  </p>
                  <p className="counts">
                    count: {data?.total_corporate_transaction_count}
                  </p>
                </div>
              </BillsCard>

              <BillsCard color="#DD7021" backgroundColor="#F5F5F5">
                <div className="icon">
                  <img src={airTotImg} alt="" />
                </div>
                <div>
                  <p className="cardName">Total Airtime</p>
                  <p className="amount">
                    ₦{data?.airtime_purchase_total?.toLocaleString()}
                  </p>
                  <p className="counts">count: {data?.airtime_count}</p>
                </div>
              </BillsCard>
              <BillsCard color="#FFDB8F" backgroundColor="#F5F5F5">
                <div className="icon">
                  <img src={corpTxnImg} alt="" />
                </div>
                <div>
                  <p className="cardName">Total Bill Payment</p>

                  <p className="amount">
                    ₦{data?.total_bill_payment_amount?.toLocaleString()}
                  </p>
                  <p className="counts">
                    count: {data?.total_bill_payment_count}
                  </p>
                </div>
              </BillsCard>
            </BillsCardWrapper>
            <BillsCardWrapper2>
              <BillsCard color="#9B9D6F" backgroundColor="#F5F5F5">
                <div className="icon">
                  <img src={totTxnImg} alt="" />
                </div>
                <div>
                  <p className="cardName">Total Transactions</p>

                  <p className="amount">
                    ₦{data?.total_transaction_amount?.toLocaleString()}
                    {/* ₦638,242,000,000,000.41 */}
                  </p>
                  <p className="counts">
                    count: {data?.total_transaction_count}
                  </p>
                </div>
              </BillsCard>
              <BillsCard2 color="#38CB89" backgroundColor="#F5F5F5">
                <div className="icon">
                  <img src={usersImg} alt="" />
                </div>
                <div className="detailsContainer">
                  <div
                    className="detailsBox"
                    style={{ borderRight: "1px solid #979797" }}
                  >
                    <p className="cardName">Individual Users</p>
                    <p className="amount">
                      {data?.total_individual_customer_count?.toLocaleString()}
                    </p>
                    <div className="activeInactive">
                      <div className="active">
                        <div className="blip"></div>
                        <p>
                          Active :{" "}
                          {data?.total_active_individual_customer_count}
                        </p>
                      </div>
                      <div className="Inactive">
                        <div className="blip"></div>
                        <p>
                          Inactive :{" "}
                          {data?.total_inactive_individual_customer_count}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="detailsBox coorpusers"
                    style={{ borderRight: "1px solid #979797" }}
                  >
                    <p className="cardName">Corporate Users</p>
                    <p className="amount">
                      {data?.total_corporate_customer_count?.toLocaleString()}
                    </p>
                    <div className="activeInactive">
                      <div className="active">
                        <div className="blip"></div>
                        <p>
                          Active : {data?.total_active_corporate_customer_count}
                        </p>
                      </div>
                      <div className="Inactive">
                        <div className="blip"></div>
                        <p>
                          Inactive :{" "}
                          {data?.total_inactive_corporate_customer_count}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="detailsBox">
                    <p className="cardName">Total users</p>
                    <p className="amount">
                      {data?.total_customer_count?.toLocaleString()}
                    </p>
                  </div>
                </div>
              </BillsCard2>
            </BillsCardWrapper2>
          </>
        )}

        <ChartWrapper>
          <LineChartWrapper>
            <div className="lineHd">
              <h3>Recent Transactions</h3>

              <div className="filterWrapper">
                <p>Filter By:</p>
                <select
                  className="capitalize"
                  onChange={(event) => {
                    //getQuo(`this_${event.target.value}`)

                    if (event.target.value == "year") {
                      setProducts(
                        localChart?.yearly?.map(
                          (month) => month[event.target.value]
                        )
                      );
                      setSales(
                        localChart?.yearly?.map((month) => month.amount)
                      );

                      setProducts2(
                        otherChart?.yearly?.map(
                          (month) => month[event.target.value]
                        )
                      );
                      setSales2(
                        otherChart?.yearly?.map((month) => month.amount)
                      );
                    }
                    if (event.target.value == "month") {
                      setProducts(
                        localChart?.monthly?.map(
                          (month) => month[event.target.value]
                        )
                      );
                      setSales(
                        localChart?.monthly?.map((month) => month.amount)
                      );

                      setProducts2(
                        otherChart?.monthly?.map(
                          (month) => month[event.target.value]
                        )
                      );
                      setSales2(
                        otherChart?.monthly?.map((month) => month.amount)
                      );
                    }
                    if (event.target.value == "week") {
                      setProducts(
                        localChart?.weekly?.map(
                          (month) => month[event.target.value]
                        )
                      );
                      setSales(
                        localChart?.weekly?.map((month) => month.amount)
                      );
                      setProducts2(
                        otherChart?.weekly?.map(
                          (month) => month[event.target.value]
                        )
                      );
                      setSales2(
                        otherChart?.weekly?.map((month) => month.amount)
                      );
                    }
                  }}
                >
                  <option value="year">Yearly</option>
                  <option value="month">Monthly</option>
                  <option value="week">Weekly</option>
                </select>
              </div>
            </div>
            <Bar
              localChart={localChart}
              otherChart={otherChart}
              chartType={chartType}
              data1a={products}
              data1b={sales}
              data2a={products2}
              data2b={sales2}
            />
          </LineChartWrapper>
          <PieChartWrapper className="hi">
            <Pie data={pieData} />
          </PieChartWrapper>
        </ChartWrapper>
        <TableWrapper>
          <Table dataSet={data?.recent_customer} />
        </TableWrapper>
      </PageContainer>
    </>
  );
};

export default Home;

export const PageContainer = styled.section`
  position: relative;
  padding: 1rem 2rem;

  @media only screen and (max-width: 450px) {
    padding: 1rem 0 !important;
    margin-top: 100px;
  }

  .welcome {
    font-size: 18px;
    margin-top: 20px !important;
  }
`;

const BigWrapper = styled.div`
  background: #fbfbfb;
  padding-bottom: 5px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    background: #fff;
  }

  .searchInput {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .search-box {
    display: flex;
    align-items: center;
    margin-left: 50%;
    background: #f8fdff;
    width: 27.5em;
    height: 30px;
    padding: 0 10px;
    border-radius: 10px;

    svg {
      color: grey;
    }

    input {
      width: 100%;
      background: transparent;
      border: none;
      outline: none;
      margin-left: 10px;
    }
  }
`;
const BigCardWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 30px;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 450px) {
    flex-direction: column;
    gap: 10px;
    margin-top: 50px;
  }

  .card-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 10px;
    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    @media only screen and (max-width: 450px) {
      width: 100%;
      justify-content: center;
      margin: 0 auto;
    }

    .card {
      width: 13.6rem;
      height: 100px;
      border-radius: 15px;
      display: flex;
      align-items: flex-start;
      -webkit-box-shadow: 0px 7px 5px -2px rgba(0, 0, 0, 0.1);
      @media only screen and (max-width: 450px) {
        width: 90%;
        margin: 0 auto;
      }
      @media only screen and (max-height: 736px) {
        height: 80px;
      }

      img {
        width: 60px;
      }
      .trsf-details {
        p {
          margin: 0 !important;
          margin-top: 10px !important;
          font-weight: 500;
          font-size: 14px;
        }
        small {
          font-size: 8px;
          font-weight: bold;
          margin: 0 !important;
          margin-top: 2px !important;
        }
        h6 {
          margin: 0 !important;
          margin-top: 10px !important;
          font-size: 14px;
        }

        .mobile-users-details {
          display: flex;
          gap: 8px;

          .active-detail {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-top: 7px;
            margin-bottom: 1px;

            p {
              margin-top: 1px !important;
              font-size: 8px;
              font-weight: bold;
            }

            .active-blip {
              margin-top: 2px !important;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: #d22f31;
            }
          }

          .inactive-detail {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-top: 7px;
            margin-bottom: 1px;

            p {
              margin-top: 1px !important;
              font-size: 8px;
              font-weight: bold;
            }
            .inactive-blip {
              margin-top: 2px !important;
              width: 3.5px;
              height: 4px;
              border-radius: 50%;
              background: #ff0000b2;
            }
          }
        }
      }
    }
  }
  .board {
    @media only screen and (min-width: 1920px) {
      width: 38.5rem;
    }
    .bill-pictures {
      margin-top: 35px;
      display: flex;
      justify-content: space-around;

      .bill-card {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 450px) {
          width: 40px;
          height: 40px;
        }
        @media only screen and (max-height: 736px) {
          width: 40px;
          height: 40px;
        }
        img {
          width: 20px;
          height: 20px;
          @media only screen and (max-width: 450px) {
            width: 15px;
          }
        }
      }
    }

    .welcome-board {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      border-radius: 15px;
      /* background: rgb(125, 219, 166); */
      background: linear-gradient(90deg, #d22f31 30%, rgb(203, 203, 200) 100%);
      height: 6.3rem;
      width: 26rem;
      @media only screen and (min-width: 1920px) {
        min-width: 36rem;
      }

      @media only screen and (min-width: 1440px) {
        width: 36.4rem;
      }
      @media only screen and (max-width: 450px) {
        width: 20.4rem;
        margin: 0 auto;
        height: 6.3rem;
      }
      @media only screen and (max-height: 736px) {
        width: 20.4rem;
        margin: 0 auto;
        height: 4.8rem;
      }

      img {
        width: 120px;
        @media only screen and (max-width: 450px) {
          width: 80px;
          height: 100px;
        }
        @media only screen and (max-height: 736px) {
          width: 80px;
          height: 80px;
        }
      }
      .welcome-name {
        color: #ffffff;

        h2 {
          font-size: 18px;
          margin-top: -15px;
          @media only screen and (max-width: 414px) {
            font-size: 13px;
          }
        }
        p {
          @media only screen and (max-width: 414px) {
            font-size: 10px;
          }
        }
      }
    }
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  height: 390px;
  margin: 15px 0 0;
  justify-content: space-between;
`;
const LineChartWrapper = styled.div`
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.2);
  height: 100%;
  flex: 0.8;

  .lineHd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    h3 {
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
    .filterWrapper {
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        font-size: 13px;
      }

      select {
        border: #000;
        outline: #000;
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 1440px) {
    width: 54.8rem;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 500px;
  }
`;

const PieChartWrapper = styled.div`
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 9px 1px rgba(81, 69, 159, 0.09);
  height: 100%;
  flex: 0.26;
  display: flex;
  justify-content: center;
`;
const TableWrapper = styled.div`
  width: 100%;
`;

const BillsCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 15px;
  flex-wrap: row wrap;
  align-items: center;
`;

const BillsCardWrapper2 = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  column-gap: 15px;
  flex-wrap: row wrap;
  align-items: center;
  margin-top: -13px;
`;

const SkeletonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  row-gap: 10px;
  column-gap: 20px;
  margin-top: 20px;
`;
const SkeletonWrapper2 = styled.div`
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  column-gap: 15px;
  width: 100%;
  row-gap: 10px;
  column-gap: 20px;
  margin-top: 20px;
`;
