import React, { Fragment, useRef } from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import Content from "./Content";
import Modals from "./Modals";
import MobileNav from "./MobileNav";

const index = ({ children }) => {
  return (
    <Fragment>
      <Container id="container">
        <Modals />
        <Sidebar />
        <MobileNav />
        <Content>{children}</Content>
      </Container>
    </Fragment>
  );
};

export default index;

const Container = styled.div`
  position: relative;
`;
