import React from "react";
import styled from "styled-components";

const BillsCard2 = ({ children, color, backgroundColor }) => {
  const Card = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    height: 121px;
    left: 3%;
    border-radius: 9px;
    overflow: hidden;
    padding: 0.6rem 0.9rem 0.6rem 2.9rem;
    box-shadow: 4px 0px 13px 5px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 4px 0px 13px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 4px 0px 13px 5px rgba(0, 0, 0, 0.05);
    border-left: 10px solid ${color};

    .icon {
      background-color: ${backgroundColor};
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      border-radius: 50%;
      justify-content: center;

      img {
        width: 25px;
        height: 25px;
      }
    }

    .detailsContainer {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      flex: 2;
      margin: 10px 20px 10px 0;
      .detailsBox {
        width: 100%;
        flex: 1.4;
        .cardName {
          font-size: 11px;
          margin: 19px 0 15px !important;
          font-weight: 600;
          color: #909090;
        }
        .amount {
          font-family: "DM Sans", sans-serif !important;
          font-size: 20px;
          font-weight: 600;
        }
        .counts {
          margin: 12px 0 !important;
          font-size: 10px;
          font-weight: 600;
          color: #909090;
        }
        .activeInactive {
          display: flex;
          align-items: center;
          font-size: 10px;
          margin: 6px 0 !important;
          color: #909090;

          .active {
            margin-right: 10px;
            display: flex;
            align-items: center;
            gap: 4px;
            .blip {
              background: #0e814a;
              height: 4px;
              width: 4px;
              border-radius: 50%;
            }
          }
          .Inactive {
            display: flex;
            align-items: center;
            gap: 4px;

            .blip {
              background: #ff0000b2;
              height: 4px;
              width: 4px;
              border-radius: 50%;
            }
          }
        }
      }
      .coorpusers {
        flex: 1.7;
      }
    }
  `;

  return (
    <div style={{ marginTop: "30px" }}>
      <Card>{children}</Card>
    </div>
  );
};

export default BillsCard2;
