import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

export default function Bar({
  localChart,
  data1a,
  data1b,
  data2a,
  data2b,
  chartType,
}) {
  const series = [
    {
      name: "KCMFB",
      data: data1b || [],
    },
    {
      name: "Others",
      data: data2b || [],
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },

    grid: {
      show: false,
      borderColor: "#90A4AE",
    },
    stroke: {
      curve: "smooth",
      width: "3",
    },
    colors: ["#d22f31", "#ECE9F1", "#B1E3FF", "#95A4FC", "#A1E3CB"],
    xaxis: {
      categories: data1a || [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Math.abs(val) > 999999999
            ? `₦ ${Math.sign(val) * (Math.abs(val) / 1000000000).toFixed(1)}B`
            : Math.abs(val) > 999999
            ? `₦${Math.sign(val) * (Math.abs(val) / 1000000).toFixed(1)}M`
            : Math.abs(val) > 999
            ? `₦${Math.sign(val) * (Math.abs(val) / 1000).toFixed(1)}k`
            : Math.sign(val) * Math.abs(val);
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "25%",
        distributed: true,
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5,
      },
    },

    dataLabels: {
      enabled: false,
    },
  };

  return (
    <div>
      <Chart options={options} series={series} height={300} />
    </div>
  );
}
