import React, { useState, useEffect } from "react";
import ReusableTable from "../../../../reusables/table/ReusableTable";
import { Link } from "react-router-dom";
import { tableData } from "./tableData";
import styled from "styled-components";
import SearchInput from "../../../../reusables/inputs/SearchInput";
import { BASE_URL } from "../../../../config/config";

const Table = ({ dataSet }) => {
  const individualColumns = [
    { field: "no", header: "No" },
    { field: "name", header: "Full Name" },
    { field: "email", header: "Email Address" },
    { field: "number", header: "Phone Number" },
    { field: "date", header: "Date Of Birth" },
    { field: "gender", header: "Gender" },
  ];
  const corportateColumns = [
    { field: "no", header: "S/N" },
    { field: "name", header: "INSTITUTIONS NAME" },
    { field: "email", header: "SHORT CODE" },
    { field: "number", header: "ACCOUNT NO" },
    { field: "gender", header: "CUSTOMER ID" },
    { field: "gender", header: "DATE JOINED" },
    { field: "date", header: "ACTION" },
  ];
  const [init, setInit] = useState(1);
  const [tableType, setTableType] = useState("recentIndividuals");
  const [tableColumn, setTableColumn] = useState(individualColumns);
  const [instituions, setInstitutions] = useState();
  const token = JSON.parse(sessionStorage.getItem("token"));

  const myData = tableData.slice(0, 10);

  const getSignatories = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/institution/?page=1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setInstitutions(data?.results);
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    getSignatories();
  }, []);
  return (
    <TableWrapper>
      <div className="recentHead">
        <h4>Recent Customers</h4>
      </div>
      <div className="buttons">
        <button
          onClick={() => {
            setTableType("recentIndividuals");
          }}
          className={tableType === "recentIndividuals" ? "active" : ""}
        >
          Individuals
        </button>
        <button
          onClick={() => {
            setTableType("recentCorporate");
          }}
          className={tableType === "recentCorporate" ? "active" : ""}
        >
          Corporate
        </button>
      </div>
      <ReusableTable
        type={tableType}
        init={init}
        columns={
          tableType === "recentIndividuals"
            ? individualColumns
            : corportateColumns
        }
        data={tableType === "recentIndividuals" ? dataSet : instituions}
      />
      <Link
        to={
          tableType === "recentIndividuals" ? "/requests" : "/corporate-banking"
        }
      >
        View All
      </Link>
    </TableWrapper>
  );
};

export default Table;

const TableWrapper = styled.div`
  /* width: 100%; */
  padding: 20px 20px 80px;
  margin-top: 15px;
  background: #fff;
  border-radius: 30px;
  position: relative;

  .recentHead {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      margin: 0 !important;
    }
  }

  a {
    right: 20px;
    position: absolute;
    margin-top: 20px;
    text-decoration: none;
    font-size: 13px;
    color: #d22f31;
    border: 1px solid;
    padding: 5px 10px;
  }
  .buttons {
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 20px;

    button {
      border: none;
      outline: none;
      background: transparent;
      color: #9a9797;
      font-size: 14px;
      transition: all 0.3s ease-in;
      cursor: pointer;

      &:hover {
        color: #d22f31;
        border-bottom: 2px solid;
      }
    }
    .active {
      color: #d22f31;
      border-bottom: 2px solid;
    }
  }
`;
