import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Search from "../../../reusables/table-search/Search";
import addIcon from "../../../assets/icons/add-icon2.svg";
import Modal from "./AddCorporate/Modal";
import { BASE_URL } from "../../../config/config";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { PaginationWrapper, PaginationBtnWrapper } from "../Customers/Home";
import ReusableTable from "../../../reusables/table/ReusableTable";

const Home = () => {
  const [showInstitutionModal, setShowInstitutionModal] = useState(false);
  const token = JSON.parse(sessionStorage.getItem("token"));
  const [instituions, setInstitutions] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [next, setNext] = useState(false);
  const [prev, setPrev] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [pages, setPages] = useState();
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [startCount, setStartCount] = useState(1);
  const [endCount, setEndCount] = useState(postsPerPage);
  const [startPoint, setStartPoint] = useState(0);
  const [endPoint, setEndPoint] = useState(5);
  const [currListLength, setCurrListLength] = useState();
  const [init, setInit] = useState(1);
  const [data, setData] = useState();
  const [searched, setSearched] = useState(false);

  const navigate = useNavigate();

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(pages / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const endPage = Math.ceil(pages / postsPerPage);

  const prevPageHandler = async () => {
    if (currentPage === 1) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
      setStartCount(startCount - 10);
      setEndCount(endCount - currListLength);
    }
  };
  const nextPageHandler = async () => {
    if (currentPage >= endPage) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
      setStartCount(startCount + 10);
      setEndCount(endCount + currListLength);
    }
  };

  useEffect(() => {
    if (currentPage === endPage) {
      setEndCount(pages);
    } else {
      return;
    }
  }, [currentPage]);

  const myPageNumbers = pageNumbers.slice(startPoint, endPoint);

  /*   This is where you get all instituions
   */
  const getSignatories = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/institution/?page=${currentPage}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      //console.log(data);
      setLoading(false);
      setData(data);
      setInstitutions(data?.results);
      setCurrListLength(data?.results?.length);
      setPages(data?.count);
      if (data?.results?.length < 10) {
        setEndCount(data?.results?.length);
      }
      if (data?.next === null) {
        setNext(false);
      } else {
        setNext(true);
      }
      if (data?.previous === null) {
        setPrev(false);
      } else {
        setPrev(true);
      }
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSignatories();
  }, [currentPage, isAdd]);

  const columns = [
    { field: "no", header: "S/N" },
    { field: "name", header: "INSTITUTIONS NAME" },
    { field: "email", header: "SHORT CODE" },
    { field: "number", header: "ACCOUNT NO" },
    { field: "gender", header: "CUSTOMER ID" },
    { field: "gender", header: "DATE JOINED" },
    { field: "date", header: "ACTION" },
  ];

  /* The Commented code below will fetch institutions based on search params */

  // const onSearchHandler = async () => {
  //   setSearched(true);
  //   setCurrentPage(1);

  //   const getSignatories = async () => {
  //     try {
  //       setLoading(true);
  //       const response = await fetch(
  //         `${BASE_URL}/api/institution/?page=${currentPage}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       const data = await response.json();
  //       //console.log(data);
  //       setLoading(false);
  //       setData(data);
  //       setInstitutions(data?.results);
  //       setCurrListLength(data?.results?.length);
  //       setPages(data?.count);
  //       if (data?.next === null) {
  //         setNext(false);
  //       } else {
  //         setNext(true);
  //       }
  //       if (data?.previous === null) {
  //         setPrev(false);
  //       } else {
  //         setPrev(true);
  //       }
  //     } catch (error) {
  //       //console.log(error);
  //       setLoading(false);
  //     }
  //   };
  //   getSignatories();

  //   // setSearchParams()
  // };

  // const onKeyPressHandler = (e) => {
  //   //console.log(e);
  //   if (e.code === "Backspace" && e.target.value.length <= 1) {
  //     setCurrentPage(1);
  //     const getSignatories = async () => {
  //       try {
  //         setLoading(true);
  //         const response = await fetch(
  //           `${BASE_URL}/api/institution/?page=${currentPage}`,
  //           {
  //             method: "GET",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         );
  //         const data = await response.json();
  //         //console.log(data);
  //         setLoading(false);
  //         setData(data);
  //         setInstitutions(data?.results);
  //         setCurrListLength(data?.results?.length);
  //         setPages(data?.count);
  //         if (data?.next === null) {
  //           setNext(false);
  //         } else {
  //           setNext(true);
  //         }
  //         if (data?.previous === null) {
  //           setPrev(false);
  //         } else {
  //           setPrev(true);
  //         }
  //       } catch (error) {
  //         //console.log(error);
  //         setLoading(false);
  //       }
  //     };
  //     getSignatories();
  //   }
  // };

  /* The Commented code above will fetch institutions based on search params */

  return (
    <Wrapper>
      <PageContainer>
        <div className="head">
          <h3>Institutions</h3>
          {/* <Search /> */}
        </div>
        <div className="addInstitute">
          {loading ? (
            ""
          ) : (
            <p className="institutionCount">
              {data?.count} {data?.count > 1 ? "Institutions" : "Institution"}
            </p>
          )}
          <button
            onClick={() => {
              setShowInstitutionModal(true);
            }}
          >
            <img src={addIcon} alt="" />
            Add Institution
          </button>
        </div>
        <div className="institutionHd">
          {loading ? (
            <Skeleton height={500} style={{ marginTop: "10px" }} />
          ) : (
            <div style={{ marginTop: "30px" }}>
              <ReusableTable
                type="corporates"
                columns={columns}
                data={instituions}
                init={init}
              />
            </div>
          )}
        </div>

        {loading ? (
          ""
        ) : (
          <PaginationWrapper style={{ padding: "0 20px" }}>
            <h5>
              Showing {startCount} to {endCount} of {pages} Entries
            </h5>
            <PaginationBtnWrapper>
              <button onClick={prevPageHandler}>Previous</button>
              {myPageNumbers.map((number) => (
                <div key={number} className="numbers">
                  <p
                    href="#"
                    style={{
                      background: currentPage === number ? "#868d024a" : "",
                    }}
                    key={number}
                  >
                    {number}
                  </p>
                </div>
              ))}
              <button onClick={nextPageHandler}>Next</button>
            </PaginationBtnWrapper>
          </PaginationWrapper>
        )}
      </PageContainer>
      {showInstitutionModal && (
        <Modal
          setShow={setShowInstitutionModal}
          setIsAdd={setIsAdd}
          isAdd={isAdd}
        />
      )}
    </Wrapper>
  );
};

export default Home;

const Wrapper = styled.section`
  padding: 0.5rem 2rem;
`;
const PageContainer = styled.div`
  padding-bottom: 30px;
  border-radius: 10px;
  background: #fff;
  position: relative;

  .prevNextWraper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    padding-right: 30px;

    button {
      width: 120px;
      height: 37px;
      background: transparent;
      border: 1px solid #d22f31;
      color: #d22f31;
      outline: none;
      cursor: pointer;

      &:disabled {
        border: 1px solid #3d3d3d;
        color: #3d3d3d;
      }
    }
  }

  .head {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .addInstitute {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    align-items: center;

    .institutionCount {
      font-weight: 500;
      font-size: 20px;
    }

    button {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #d22f31;
      border: none;
      outline: none;
      background-color: transparent;
      border: 1px solid #d22f31;
      border-radius: 4px;
      padding: 10px;
      cursor: pointer;

      img {
        height: 20px;
      }
    }
  }

  .institutionHd {
    margin: 30px 0;

    /* padding: 0 30px; */
    p {
      font-weight: 600;
      font-size: 18px;
      padding-left: 30px;

      u {
        margin-bottom: 20px;
      }
    }
  }
  .institutionBd {
    margin-top: 20px;

    .institutions {
      margin-bottom: 14px;
      hr {
        border: 0.1px solid #eeeeeecc;
        margin: 4px 0;
      }
      .institution {
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          display: flex;
          align-items: center;
          gap: 5px;
          color: #d22f31 !important;
          border: none;
          outline: none;
          background-color: transparent;
          border: 1px solid #d22f31;
          border-radius: 4px;
          padding: 10px;
          cursor: pointer;
        }
      }
    }
  }
`;
